// libraries
import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
// components
import Seo from 'components/shared/Seo';
import Header from 'components/shared/Header';
// styles
import '../../index.scss';

const MainLayout = ({ children, seoTitle }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <>
      <Seo title={seoTitle} />

      <Header siteTitle={data.site.siteMetadata.title} />

      <div className="content">
        <main>
          {children}
        </main>

        <footer>
          ©
          {' '}
          {new Date().getFullYear()}
        </footer>
      </div>
    </>
  );
};

export default MainLayout;
