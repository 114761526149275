// libraries
import React from 'react';
// components
import MainLayout from 'layouts/Main';

const NotFoundPage = () => (
  <MainLayout seoTitle="404: Not found">
    <h1>NOT FOUND</h1>
    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
  </MainLayout>
);

export default NotFoundPage;
